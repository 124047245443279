import React from "react";
import Footer from "components/shared/Footer";
import Logos from "components/Logos";
import Nav from "components/shared/Nav";
import Meta from "components/shared/Meta";
import Layout from "components/shared/Layout";
import { intro, container, content, container_conten, entryContent } from "./index.module.less";

export default (queryResults) => {
  return (
    <>
      <Meta url="/about" />
      <Nav activeSection={"About"} color={"dark"} />

      <Layout>
        <div style={{height:"125px"}}></div>
        <div className={[container_conten]}>
          <div className={[entryContent]}>
            <div>
              <div>
                <h1>关于我们</h1>
                <p>
                  2017年，中国和英国政府共同发布了《中英科技创新合作战略》。这是两国之间的第一个双边科技创新合作战略，它为中英科技合作提供了一个惠及两国的蓝图。中英健康老龄化项目就是对这一合作战略的具体实施。
                </p>

                <p>
                  中英健康老龄化项目旨在促进两国老龄化科技解决方案的创新和发展，积极应对人口老龄化，帮助公民更健康、更长寿。作为合作的一部分，中英双方都领导了各自的项目，并互相分享学习成果和知识。在英国，英国国家老龄创新研究中心（NICA）是主要的合作伙伴，重点关注老龄化的三个方面：移动性、步态和确保城市环境的调整以促进独立生活。
                </p>

                <p>
                  NICA是一个由英国政府创建的世界级领先机构，由纽卡斯尔大学主办并担任责任方。NICA将在该项目中举办一系列活动。这些活动将包括公民参与、双边商业创新任务，以及为英国企业提供共同办公空间和加速器计划。
                </p>

                <p>
                  公民参与是NICA的一个核心部分，也是我们在中国的合作工作中的一个基本组成。通过与当地的伙伴和组织合作，我们将建立一个公民社区，用来收集以人为本的宝贵见解。这将通过开发一个参与平台来实现，通过这个平台，社区成员们将能够参与到各种活动中，为广泛主题的调研活动贡献他们的想法、意见和生活经验。这些参与者将帮助塑造新一代促进长寿的产品和服务。
                </p>
              </div>
            </div>
          </div>
        </div>
        <div style={{height:"80px"}}></div>
      </Layout>

      <Logos></Logos>
      <Footer />
    </>
  );
};
